import {initInView} from "./helpers";

document.querySelectorAll('[data-layout="header"]').forEach($el => {
    import('../../../partials/layout/header/header' /* webpackChunkName: "/dist/js/header" */).then(({default: init}) => init($el));
});

document.querySelectorAll('[data-layout="menu-mobile"]').forEach($el => {
    import('../../../partials/layout/menu-mobile/menu-mobile' /* webpackChunkName: "/dist/js/menu-mobile" */).then(({default: init}) => init($el));
});

document.querySelectorAll('[data-request="onShowPopup"], .js-popup-trigger, [data-fancybox]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/popup/popup' /* webpackChunkName: "/dist/js/popup" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('.js-loader').forEach($el => {
    import('../../../partials/blocks/loader/loader' /* webpackChunkName: "/dist/js/loader" */).then(({default: init}) => init($el));
});

document.querySelectorAll('[data-block="slider"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/slider/slider' /* webpackChunkName: "/dist/js/slider" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('.js-carousel-certificates').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/carousel/carousel-certificates' /* webpackChunkName: "/dist/js/carousel-certificates" */).then(({default: init}) => init($el));
    });
});